.sidebar-tab {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.sidebar-tab-header {
    position: relative;
    padding: .5rem .5rem .5rem .25rem;
    display: flex;
    align-items: center;
    height: $app-header-height;
    background-color: $card-bg;
    box-shadow: 0 1px 0 0 rgba($black, .075);
    z-index: 5;

    @include media-breakpoint-up(md) {
        padding: .5rem;
    }
}

.sidebar-tab-header-actions {
    margin-left: auto;
    min-width: 92px;
    text-align: right;
}

.sidebar-tab-title {
    margin: 0 .5rem;
    font-size: $font-size-base;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.sidebar-tab-body {
    padding: .3rem 1rem .3rem .3rem;
    flex: 1;
    overflow: auto;
    height: 100%;
}

.sidebar-tab-footer {
    position: relative;
    padding: .5rem;
    background-color: $input-bg;
    box-shadow: 0 -1px 0 0 rgba($black, .075);
    z-index: 1;
}
