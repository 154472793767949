// Responsive card-deck

@each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .card-deck#{$infix} {
        display: block;

        @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
            display: flex;
            flex-flow: row wrap;
            //gap: 10px;
            margin-right: -$card-deck-margin;
            margin-left: -$card-deck-margin;

            .card {
                display: flex;
                // Flexbugs #4: https://github.com/philipwalton/flexbugs#4-flex-shorthand-declarations-with-unitless-flex-basis-values-are-ignored
                flex: 1 0 0%;
                flex-direction: column;
                margin-right: $card-deck-margin;
                margin-left: $card-deck-margin;
            }
        }
    }
}

.row .card {
    height: calc(100% - 20px);
}

.card {
    margin-bottom: $grid-gutter-width;
    border: none;
    box-shadow: 0 0 0 1px rgba($black, .05), 0 1px 3px 0 rgba($black, .15);

    // remove border radius on xs screen
    &-fluid {
        @include media-breakpoint-down(xs) {
            border-radius: 0;
            border-width: 1px 0;

            .card-img-top {
                @include border-top-radius(0);
            }

            .card-img-bottom {
                @include border-bottom-radius(0);
            }
        }
    }

    &-reflow {
        margin-bottom: 0;
        display: block;
        background-color: transparent;
        box-shadow: none;
    }
}

.card-header-tabs {
    margin-top: (-$card-spacer-y);
    font-weight: normal;
}

.card-header-control,
.card-title-control {
    margin: -.5rem 0;
}

.card-header {
    font-weight: 600;

    &-fluid {
        padding-bottom: 0;
        border-bottom: 0;
    }
}

.card-title {
    font-size: $font-size-base;

    > a {
        color: inherit;
        outline: 0;

        &:hover,
        &:focus {
            color: inherit;
            text-decoration: none;
        }
    }
}

.card-subtitle {
    margin-top: -.75rem;
    font-size: $font-size-sm;
    font-weight: normal;

    @include media-breakpoint-up(md) {
        font-size: $font-size-xs;
    }
}

.card-footer {
    padding: 0;
    display: flex;
    align-items: stretch;
}

.card-footer-content {
    padding: $card-spacer-y $card-spacer-x;
    width: 100%;
}

.card-footer-item {
    padding: $card-spacer-y $card-spacer-x;
    flex: 1;
    text-align: center;
    outline: 0;

    &:hover,
    &:focus {
        text-decoration: none;
    }

    &-bordered:not(:last-child) {
        border-right: 1px solid $card-border-color;
    }

    > .metric {
        padding: 0;
    }
}

a.card-footer-item {
    &.text-muted {
        &:hover,
        &:focus {
            color: $gray-800 !important;
        }
    }
}

.card-link + .card-link {
    margin-left: 0;
}


// Card expansion (accordion)
// ==================================================

.card-expansion {
    margin-bottom: 1rem;
}

.card-expansion-item {
    margin-bottom: 0;
    border-radius: 0;
    transition: margin 200ms;

    &.expanded {
        margin: 1rem 0;
    }

    &:first-child {
        @include border-top-radius($card-border-radius);

        &.expanded {
            margin-top: 0;
        }
    }
    &:last-child {
        @include border-bottom-radius($card-border-radius);

        &.expanded {
            margin-bottom: 0;
        }
    }

    &:not(.expanded) + .card-expansion-item:not(.expanded) {
        margin-top: -1px;
    }
}

// collapse indicator

[data-toggle="collapse"] {
    .collapse-indicator {
        text-align: left;
        color: $text-muted;

        > .fa,
        > .oi {
            transform: rotate(0deg);
            transition: transform 200ms;
        }
    }

    &[aria-expanded="true"] .collapse-indicator {
        > .fa-plus:before {
            content: '\f068';
        }
        > .oi-plus:before {
            content: '\e09d';
        }

        > .fa-angle-down,
        > .fa-caret-down,
        > .fa-chevron-down,
        > .oi-caret-down,
        > .oi-chevron-down {
            transform: rotate(-180deg);
        }

        > .fa-angle-left,
        > .fa-caret-left,
        > .fa-chevron-left,
        > .oi-caret-left,
        > .oi-chevron-left {
            transform: rotate(-90deg);
        }

        > .fa-angle-right,
        > .fa-caret-right,
        > .fa-chevron-right,
        > .oi-caret-right,
        > .oi-chevron-right {
            transform: rotate(90deg);
        }

        > .fa-circle,
        > .fa-dot-circle {
            color: $primary;

            &::before {
                content: '\f192';
            }
        }
    }
}

//
// Inverse text within a card for use with dark backgrounds
//

.card-inverse {
    color: rgba($white, .6);

    .card-header,
    .card-footer {
        background-color: transparent;
        border-color: rgba($white, .2);
    }

    .card-header,
    .card-footer,
    .card-title,
    .card-blockquote {
        color: rgba($white, .8);
    }

    .card-footer-item-bordered {
        border-color: rgba($white, .2);
    }

    .card-link,
    .card-text,
    .card-subtitle,
    .card-blockquote .blockquote-footer {
        color: rgba($white, .6);
    }

    .card-link {
        &:hover,
        &:focus {
            color: rgba($white, .8);
        }
    }
}
