.list-group {
    border: 0;
    box-shadow: 0 0 0 1px rgba($black, .05), 0 1px 3px 0 rgba($black, .15);

    &-flush,
    &-reflow {
        box-shadow: none;
    }

    &-reflow {
        .list-group-header,
        .list-group-item {
            background-color: transparent;
        }
    }

    &-fluid {
        .list-group-item {
            padding-right: 0;
            padding-left: 0;
        }
    }
}


.list-group-header {
    margin: 0;
    padding: .75rem $list-group-item-padding-x $list-group-item-padding-y;
    display: flex;
    font-size: $font-size-xs;
    font-weight: 600;
    color: $text-muted;
    background-color: $list-group-bg;

    &:not(:first-child) {
        margin-top: 1px;
        border-top: 1px solid $list-group-border-color;
    }

    + .list-group-item {
        .list-group-item-body,
        .list-group-item-figure {
            border-top-color: transparent !important;
        }
    }
}

.list-group-item {
    display: flex;
    align-items: stretch;
    border-width: 1px 0;
    border-color: transparent;
    outline: 0;

    &:first-child {
        border-width: 0 0 1px;
    }

    &:last-child {
        border-width: 1px 0 0;
    }

    &.active {
        color: $list-group-active-color;
        border-color: transparent;
        border-radius: 0 !important;
        box-shadow: inset 4px 0 0 0 $list-group-active-border-color;

        .list-group-item-figure {
            color: $component-active-bg;
        }

        .list-group-item-text {
            color: $list-group-active-color;
        }

        &:hover,
        &:focus {
            .list-group-item-figure {
                color: $component-active-bg;
            }
        }
    }
}

.list-group-item-action {
    &:hover,
    &:focus {
        .list-group-item-figure {
            color: $text-muted;
        }

        .list-group-item-text {
            color: $body-color;
        }
    }
}

.list-group-item-figure {
    display: flex;
    align-items: center;
    color: $gray-500;

    &:first-child {
        padding-right: 1rem;
    }

    &:last-child {
        padding-left: 1rem;
    }
}

.list-group-item-body {
    flex: 1;
    min-width: 0;
    align-self: center;
    font-size: $font-size-sm;
}

.list-group-item-title {
    margin: 0;
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: normal;
    color: $body-color;
}

.list-group-item-subtitle {
    margin: 0;
    font-size: inherit;
    line-height: 1.25rem;
    font-weight: normal;
    color: $body-color;
}

a.list-group-item-title,
a.list-group-item-subtitle,
.list-group-item-title > a,
.list-group-item-subtitle > a {
    color: $gray-800;

    &:hover,
    &:focus {
        color: $body-color;
        text-decoration: none;
    }
}
a.current-link .list-group-item-title {
    font-weight: bold;
}

.list-group-item-text {
    margin-bottom: 0px;
    line-height: 1.25rem;
    color: $text-muted;
}


// List form

.list-group-item {
    &.custom-control {
        .custom-control-label {
            width: 100%;
        }
        .custom-control-label:after,
        .custom-control-label:before {
            top: .125rem;
            right: .125rem;
            left: auto;
        }
    }

    &.custom-select {
        height: auto;
        box-shadow: none;
        border-radius: 0;

        &:focus {
            box-shadow: none;
        }
    }
}



// List state variants

.list-group-item-primary,
.list-group-item-secondary,
.list-group-item-success,
.list-group-item-danger,
.list-group-item-warning,
.list-group-item-info,
.list-group-item-light,
.list-group-item-dark {
    color: $body-color;
    background-color: $list-group-bg;
    border-color: $list-group-border-color;
    border-radius: 0 !important;

    &.list-group-item-action {
        color: $list-group-action-color;

        &:hover,
        &:focus {
            color: $list-group-action-hover-color;
            background-color: $list-group-hover-bg;
        }

        &.active {
            border-color: $list-group-border-color;
            border-radius: 0 !important;
            box-shadow: inset 4px 0 0 0 $list-group-active-border-color;
        }
    }
}

@each $color, $value in $theme-colors {
    .list-group-item-#{$color} {
        box-shadow: inset 4px 0 0 0 $value;
    }
}


// Variant styles

.list-group-bordered {
    .list-group-header {
        border-top: 0;
    }

    .list-group-item {
        border-color: $list-group-border-color;
    }
}

.list-group-divider {
    .list-group-item {
        margin-top: 0;
        padding: 0;
        border-bottom: 0;

        &.active {
            border-color: transparent;
        }

        &:not(:first-child) {
            > .list-group-item-body,
            > .list-group-item-figure:last-child {
                border-top: 1px solid $list-group-border-color;
            }
        }
    }

    .list-group-item-figure {
        padding: $list-group-item-padding-y $list-group-item-padding-x;
    }

    .list-group-item-body {
        padding: $list-group-item-padding-y 0;

        &:first-child {
            padding-left: $list-group-item-padding-x;
        }

        &:last-child {
            padding-right: $list-group-item-padding-x;
        }
    }
}


.list-group-media {
    background-color: transparent;
    box-shadow: none;
    border-radius: 0;

    .list-group-item {
        margin-bottom: 0;
        padding: 0;
        border: 0;
        background-color: $list-group-bg;
        border-radius: $border-radius;
        box-shadow: 0 0 0 1px rgba($black, .05), 0 1px 3px 0 rgba($black, .15);

        &:not(:first-child) {
            margin-top: $spacer;
        }
    }

    .list-group-item-action {
        &:hover,
        &:focus {
            background-color: $list-group-bg;
        }
    }

    .list-group-item-figure {
        position: relative;
        padding: 0;
        width: 12rem;
        max-width: 40%;
        overflow: hidden;

        > img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            min-height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    .list-group-item-body {
        padding: ($list-group-item-padding-x * 1.5) $list-group-item-padding-x;
    }

    .list-group-item-title {
        margin-bottom: .5rem;
        font-weight: bold;
    }
}
