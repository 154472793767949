.modal-xxl {
    max-width: 1440px;
}

.modal-dialog{
    // Fullscreen modal
    &.modal-fullscreen{
        width: 100%;
        height: 100%;
        max-height: unset;
        margin: 0;
        padding: 0;
        max-width: none;

        .modal-content {
            height: auto;
            min-height: 100%;
            border-radius: 0;
            border: none;
        }
    }
}
