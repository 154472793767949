.user-avatar {
    position: relative;
    margin-bottom: 0;
    display: inline-block;
    width: $avatar-size;
    height: $avatar-size;
    font-size: $avatar-size;
    vertical-align: middle;
    border-radius: 4rem;

    &-xxl {
        width: $avatar-size-xxl;
        height: $avatar-size-xxl;
        font-size: $avatar-size-xxl;
    }

    &-xl {
        width: $avatar-size-xl;
        height: $avatar-size-xl;
        font-size: $avatar-size-xl;
    }

    &-lg {
        width: $avatar-size-lg;
        height: $avatar-size-lg;
        font-size: $avatar-size-lg;
    }

    &-md {
        width: $avatar-size-md;
        height: $avatar-size-md;
        font-size: $avatar-size-md;
    }

    &-sm {
        width: $avatar-size-sm;
        height: $avatar-size-sm;
        font-size: $avatar-size-sm;
    }

    &-xs {
        width: $avatar-size-xs;
        height: $avatar-size-xs;
        font-size: $avatar-size-xs;
    }

    &.dropdown {
        .dropdown-menu {
            min-width: 6rem;
        }

        &:hover,
        &:focus,
        &.focus,
        &.show {
            .avatar-badge > .oi {
                &:before {
                    content: "\e02d";
                }
            }
            .avatar-badge > .fa {
                &:before {
                    content: "\f0d7";
                }
            }
            .offline {
                color: $white;
            }
        }
    }

    > a {
        color: inherit;
        outline: 0;

        &:hover,
        &:focus {
            color: inherit;
            text-decoration: none;
        }
    }

    img,
    canvas,
    video,
    audio {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 4rem;
        object-fit: cover;
        object-position: top;
    }
}

a.user-avatar {
    &:hover,
    &:focus {
        outline: 0;
    }

    &:focus {
        box-shadow: 0 0 0 3px $primary;
    }
}

.user-avatar-lg {
    .avatar-badge {
        right: 5px;
    }
}

.user-avatar-sm,
.user-avatar-xs {
    .avatar-badge {
        right: -2px;
    }
}

// used by avatar lg & xl
.user-avatar-floated {
    margin-top: -50%;
    box-shadow: 0 0 0 2px $white;
    z-index: 2;

    &.user-avatar-xl {
        box-shadow: 0 0 0 3px $white;
    }

    &.user-avatar-xxl {
        box-shadow: 0 0 0 4px $white;
    }
}

.avatar-group {
    display: inline-block;

    .user-avatar {
        box-shadow: 0 0 0 2px $white;

        &:hover,
        &:focus {
            z-index: 2;
        }
    }

    .user-avatar + .user-avatar {
        display: inline-block;
        margin-left: -.5em;
    }
}

.avatar-group-animated {
    .user-avatar {
        &:nth-child(1) { animation: avatarAnimatedStart 1s; }
        &:nth-child(2) { animation: avatarAnimated .75s; }
        &:nth-child(3) { animation: avatarAnimated 1s; }
        &:nth-child(4) { animation: avatarAnimated 1.25s; }
        &:nth-child(5) { animation: avatarAnimated 1.5s; }
    }

    .tile {
        animation: avatarAnimatedEnd 1.5s;
    }
}

/* progress animation */
@keyframes avatarAnimatedStart {
    0% { opacity: 0; }
    100% { opacity: 1; }
}
@keyframes avatarAnimated {
    0% { opacity: 0; transform: translateX(-100%); }
    25% { opacity: 0; transform: translateX(-75%); }
    100% { opacity: 1; transform: translateX(0); }
}
@keyframes avatarAnimatedEnd {
    0% { opacity: 0; transform: translateX(100%); }
    25% { opacity: 0; transform: translateX(75%); }
    100% { opacity: 1; transform: translateX(0); }
}


.avatar-badge {
    position: absolute;
    right: 1px;
    bottom: 0;
    display: block;
    width: 8px;
    height: 8px;
    line-height: 1;
    text-align: center;
    font-size: 8px;
    color: $white;
    background-color: $gray-500;
    border-radius: 8px;
    box-shadow: 0 0 0 2px $white;
    z-index: 2;

    &.has-indicator {
        width: 10px;
        height: 10px;
    }

    &.online {
        background-color: $teal;
    }
    &.idle {
        background-color: $orange;
    }
    &.busy {
        background-color: $red;
    }
    &.offline {
        color: $gray-500;
        background-color: $gray-500;
    }

    .fa {
        line-height: 10px;
    }
}
