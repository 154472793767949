.mention {
    color: $mention-color;
    background-color: unset;
    padding: unset;
}

.conversation-outbound .mention {
    color: $white;
}

.mention-missing {
    color: $red;
    background-color: unset;
    padding: unset;
}

.hashtag {
    font-weight: 500;
}
